import React, { Component } from "react"
import BlogLayout from '../components/BlogLayout'
import styled from 'styled-components'

const H1 = styled.h1`
    font-size: 800%;
    color: #1B4965;
`
const Div = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`

class Resume extends Component{
    render(){
        return(
            <BlogLayout>
                <Div>
                    <H1>Coming Soon!!!</H1>
                </Div>
            </BlogLayout>
        )
    }
}

export default Resume